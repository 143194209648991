var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", persistent: "" },
      model: {
        value: _vm.showDialog,
        callback: function($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog"
      }
    },
    [
      _c(
        "v-card",
        { staticClass: "m-2", attrs: { id: "portalUrlForm" } },
        [
          _c("validation-observer", { ref: "portalUrlForm" }, [
            _c(
              "form",
              {
                attrs: { id: "portal-url-form" },
                on: {
                  submit: function($event) {
                    $event.preventDefault()
                    return _vm.openPortalSignIn.apply(null, arguments)
                  }
                }
              },
              [
                _c(
                  "v-toolbar",
                  {
                    staticClass: "elevation-0",
                    attrs: { dark: "", color: "#3F51B5" }
                  },
                  [
                    _c("v-toolbar-title", [_vm._v("Enterprise URL")]),
                    _c("v-spacer"),
                    _c(
                      "v-btn",
                      {
                        attrs: { icon: "", dark: "" },
                        on: {
                          click: function($event) {
                            _vm.$emit("portal-url-dialog-close")
                            _vm.portalSignInClientId = ""
                          }
                        }
                      },
                      [_c("v-icon", [_vm._v(_vm._s(_vm.mdiClose))])],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-card-text",
                  [
                    _c(
                      "v-row",
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "12" } },
                          [
                            _c("validation-provider", {
                              ref: "urlInput",
                              attrs: {
                                name: "URL for ArcGIS Enterprise",
                                rules: {
                                  required: true,
                                  regex: /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/
                                }
                              },
                              scopedSlots: _vm._u([
                                {
                                  key: "default",
                                  fn: function(ref) {
                                    var errors = ref.errors
                                    var valid = ref.valid
                                    return [
                                      _c("v-text-field", {
                                        attrs: {
                                          label:
                                            "Specify a URL for ArcGIS Enterprise",
                                          "hide-details": "auto",
                                          "error-messages": errors,
                                          success: valid,
                                          color: "#3F51B5",
                                          name: "portalUrl",
                                          "persistent-hint": "",
                                          hint:
                                            "Example: https://<server>/portal",
                                          id: "arcgis-enterprise-url"
                                        },
                                        on: {
                                          keyup: _vm.getPortalClientId,
                                          blur: _vm.getPortalClientId
                                        },
                                        model: {
                                          value: _vm.portalUrl,
                                          callback: function($$v) {
                                            _vm.portalUrl = $$v
                                          },
                                          expression: "portalUrl"
                                        }
                                      })
                                    ]
                                  }
                                }
                              ])
                            })
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            staticClass: "d-flex justify-end",
                            attrs: { cols: "12" }
                          },
                          [
                            _c(
                              "v-btn",
                              {
                                attrs: {
                                  type: "submit",
                                  color: "#3F51B5",
                                  disabled: !_vm.portalSignInClientId,
                                  id: "continue-arcgis-enterprise-signin"
                                }
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    style: {
                                      color: _vm.portalSignInClientId
                                        ? "white"
                                        : undefined
                                    }
                                  },
                                  [_vm._v(" Continue ")]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
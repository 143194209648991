<template>
  <v-dialog v-model="showDialog" width="500" persistent>
    <v-card class="m-2" id="portalUrlForm">
      <validation-observer ref="portalUrlForm">
        <form @submit.prevent="openPortalSignIn" id="portal-url-form">
          <v-toolbar dark color="#3F51B5" class="elevation-0">
            <v-toolbar-title>Enterprise URL</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              icon
              dark
              @click="
                $emit('portal-url-dialog-close');
                portalSignInClientId = '';
              "
            >
              <v-icon>{{ mdiClose }}</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <validation-provider
                  v-slot="{ errors, valid }"
                  name="URL for ArcGIS Enterprise"
                  :rules="{
                    required: true,
                    regex:
                      /(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/,
                  }"
                  ref="urlInput"
                >
                  <v-text-field
                    v-model="portalUrl"
                    label="Specify a URL for ArcGIS Enterprise"
                    hide-details="auto"
                    :error-messages="errors"
                    :success="valid"
                    color="#3F51B5"
                    name="portalUrl"
                    persistent-hint
                    hint="Example: https://<server>/portal"
                    @keyup="getPortalClientId"
                    @blur="getPortalClientId"
                    id="arcgis-enterprise-url"
                  />
                </validation-provider>
              </v-col>

              <v-col cols="12" class="d-flex justify-end">
                <v-btn
                  type="submit"
                  color="#3F51B5"
                  :disabled="!portalSignInClientId"
                  id="continue-arcgis-enterprise-signin"
                >
                  <span
                    :style="{
                      color: portalSignInClientId ? 'white' : undefined,
                    }"
                  >
                    Continue
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </form>
      </validation-observer>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from "@mdi/js";
import { axiosWithRegularAuth } from "@/plugins/axios";
import getPopUpDimensions from "@/mixins/getPopUpDimensions";
import axios from "axios";
import { debounce } from "lodash";

const APIURL = process.env.VUE_APP_API_URL;
const REDIRECT_URL = process.env.VUE_APP_REDIRECT_URL;
const cancelTokenSource = axios.CancelToken.source();

export default {
  name: "ArcGisEnterpriseLoginDialog",
  data() {
    return {
      mdiClose,
      portalUrl: "https://",
      portalSignInClientId: "",
      portalLoginWindow: undefined,
      showDialog: false,
      loginWindow: undefined,
    };
  },
  props: {
    showPortalUrlDialog: Boolean,
    openOutsideLoginPage: Boolean,
  },
  async beforeMount() {
    this.showDialog = this.showPortalUrlDialog;
    const urlRegex =
      /(^|\s)((https?:\/\/)?[\w-]+(\.[\w-]+)+\.?(:\d+)?(\/\S*)?)/gi;
    const portalUrl = localStorage.getItem("portal-url");
    if (urlRegex.test(portalUrl)) {
      this.portalUrl = portalUrl;
      await this.getPortalClientId();
    } else {
      this.portalUrl = "https://";
    }

    window.addEventListener("message", this.onMessage);
  },
  beforeDestroy() {
    window.removeEventListener("message", this.onMessage);
  },
  async mounted() {
    if (this.openOutsideLoginPage) {
      this.showDialog = false;
      let auth;
      try {
        auth = JSON.parse(localStorage.getItem("auth"));
      } catch (error) {
        console.log(error);
      }
      const connectionType = auth?.organization?.gis_connection_type;
      if (connectionType === "portal") {
        await this.getPortalClientId();
        await this.openPortalSignIn();
      } else if (connectionType === "agol") {
        await this.openArcGisSignIn();
      }
    }
  },
  methods: {
    async onMessage({ data }) {
      this.portalLoginWindow?.close?.();
      this.loginWindow?.close?.();
      const { access_token: accessToken, expires_in: expiresIn } = data;
      if (!accessToken) {
        return;
      }
      const expirationDate = Date.now() + expiresIn * 1000;
      localStorage.setItem("esri_token", accessToken);
      localStorage.setItem("esri_token_expiration", expirationDate);
      try {
        const resData = await axiosWithRegularAuth.post(
          `${APIURL}/auth/esri_login`,
          {
            access_token: accessToken,
            portal_url: this.loginWindow
              ? "https://www.arcgis.com"
              : localStorage.getItem("portal-url"),
          }
        );
        const {
          data: {
            results: { refreshToken, esriUsername },
          },
        } = resData;
        try {
          localStorage.setItem("refresh_token", refreshToken);
          localStorage.setItem("esri_user", esriUsername);

          const refreshTokenData = await axiosWithRegularAuth.post(
            `${APIURL}/auth/refresh_token`,
            {
              refresh_token: localStorage.getItem("refresh_token"),
            }
          );
          const {
            data: {
              results: { token },
            },
          } = refreshTokenData;
          localStorage.setItem(
            "auth",
            JSON.stringify(refreshTokenData.data.results)
          );
          cancelTokenSource.cancel();
          localStorage.setItem("token", token);
        } finally {
          this.$emit("portal-login-success");
        }
      } catch (error) {
        if (error?.response?.status === 403) {
          this.$emit("show-invalid-arcgis-user-dialog");
        }
      }
    },
    openArcGisSignIn() {
      this.portalLoginWindow = undefined;
      const { popupWinWidth, popupWinHeight, left, top } = getPopUpDimensions();
      this.loginWindow = window.open(
        `https://www.arcgis.com/sharing/rest/oauth2/authorize?client_id=h3JBZPGpA1fkpkRz&response_type=token&redirect_uri=${REDIRECT_URL}&expiration=20160`,
        "login",
        `menubar=0,width=${popupWinWidth},height=${popupWinHeight},left=${left},top=${top}`
      );
      this.loginWindow.focus();
    },
    getPortalClientId: debounce(async function () {
      this.portalSignInClientId = "";
      if (!this.portalUrl || this.portalUrl === "null") {
        return;
      }
      localStorage.setItem("portal-url", this.portalUrl);
      try {
        const {
          data: {
            results: { client_id: clientId },
          },
        } = await axiosWithRegularAuth.get(`${APIURL}/esri/get_client_id`, {
          params: {
            portal_url: encodeURI(this.portalUrl),
          },
        });
        this.portalSignInClientId = clientId;
        this.$refs.urlInput.setErrors([]);
      } catch (error) {
        this.portalSignInClientId = "";
        this.$refs.urlInput.setErrors([error?.response?.data?.error]);
      }
    }, 1000),
    openPortalSignIn() {
      this.loginWindow = undefined;
      const portalSignInUrl = `${localStorage.getItem(
        "portal-url"
      )}/sharing/oauth2/authorize?client_id=${
        this.portalSignInClientId
      }&response_type=token&redirect_uri=${REDIRECT_URL}&expiration=20160`;
      const { popupWinWidth, popupWinHeight, left, top } = getPopUpDimensions();
      this.portalLoginWindow = window.open(
        portalSignInUrl,
        "login",
        `menubar=0,width=${popupWinWidth},height=${popupWinHeight},top=${top},left=${left}`
      );
      this.portalLoginWindow.focus();
      this.portalSignInClientId = "";
    },
  },
};
</script>
